import { EventType } from '@components/Event';
import { MicrositeConfigFragment } from '@graphql/hasura';
import React from 'react';

export enum RegistrationTypeEnum {
    Normal = 'normal',
    PreRegistrationOnly = 'pre_registration_only',
    Disabled = 'disabled'
}

export enum EventAccessTypeEnum {
    Authenticated = 'authenticated',
    AllowedSchedules = 'allowed_schedules',
    DrupalAllowedFunctions = 'drupal_allowed_functions'
}

export enum SiteBrandLabelTypeEnum {
    None = 'none',
    PresentedBy = 'presented_by',
    SupportedBy = 'supported_by'
}

export type MicrositeConfigValueType = {
    site: MicrositeConfigFragment;
    header: { showEventListMenu?: boolean; showLogo?: boolean };
    event?: EventType;
    registration: {
        type: RegistrationTypeEnum;
    };
    eventAccessType: EventAccessTypeEnum;
    siteBrandLabelType?: SiteBrandLabelTypeEnum;
};

const MicrositeConfigContext = React.createContext<MicrositeConfigValueType | null>(null);
MicrositeConfigContext.displayName = 'MicrositeConfigContext';

const MicrositeConfigProvider: React.FunctionComponent<MicrositeConfigValueType> = ({
    site,
    event,
    registration,
    eventAccessType,
    siteBrandLabelType = SiteBrandLabelTypeEnum.PresentedBy,
    ...props
}) => {
    /**
     * Currently we use define some config values by the microsite.id
     * In the future and as we learn we will save these config values in the database
     */

    //default header config
    const header = {} as MicrositeConfigValueType['header'];

    if (site.name === 'ext-ch-santen-ch') {
        header.showEventListMenu = true;
    } else if (site.name === 'ext-ch-inselspital-bern-gyn') {
        registration.type = RegistrationTypeEnum.PreRegistrationOnly;
        header.showLogo = true;
    } else if (site.name === 'FomF Event Site') {
        registration.type = RegistrationTypeEnum.Disabled;
        eventAccessType = EventAccessTypeEnum.DrupalAllowedFunctions;
    } else if (site.name === 'fomf-webup-site') {
        registration.type = RegistrationTypeEnum.Disabled;
    } else if (site.name === 'ext-de-falk-foundation-de') {
        siteBrandLabelType = SiteBrandLabelTypeEnum.SupportedBy;
    } else if (site.name == 'ext-ch-usz-ch') {
        registration.type = RegistrationTypeEnum.Disabled;
    }

    const eventsWithPreRegistration: string[] = [];

    if (event?.id && eventsWithPreRegistration.includes(event.id)) {
        registration.type = RegistrationTypeEnum.PreRegistrationOnly;
    }

    return (
        <MicrositeConfigContext.Provider
            {...props}
            value={{ site, header, event, registration, eventAccessType, siteBrandLabelType }}
        />
    );
};

export default MicrositeConfigProvider;

export function useMicrositeConfig(): MicrositeConfigValueType {
    const context = React.useContext(MicrositeConfigContext);
    if (!context) {
        throw new Error(`useMicrositeConfig must be used within a MicrositeConfigProvider`);
    }
    return context;
}
